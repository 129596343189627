/*
 * Copyright 2024 NXP
 */

const styles = {
  innerContainer: { height: "80vh" },
  messageContainer: { maxWidth: "40vw" },
  messageAlignment: { textAlign: "center" },
  pageErrorActionButtons: {
    width: { xs: "100%", sm: "auto" },
    paddingTop: 1,
  },
};

export default styles;
