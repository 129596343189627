/*
 * Copyright 2024 NXP
 */
"use client"; // Error components must be Client Components

import React, { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import WholePageError from "@/src/components/ErrorBoundary/WholePageError/WholePageError";

export default function Error({ error }: { error?: Error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return <WholePageError />;
}
