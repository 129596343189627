/*
 * Copyright 2024 NXP
 */

"use client";

import React from "react";
import Image from "next/image";
import forbidden from "@/public/images/forbidden.svg";
import { Stack, Typography } from "@mui/material";
import { useTranslations } from "next-intl";
import ActionButtonsRow from "@/src/components/ErrorBoundary/ActionButtonsRow";
import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";
import styles from "./styles";
import laptop from "@/public/images/laptop.svg";
import robot from "@/public/images/robot.svg";
import lock from "@/public/images/lock.svg";
import { GraphQLErrors } from "@apollo/client/errors";
import { NOT_FOUND, PERMISSION_DENIED, UNKNOWN_VERSION } from "@/src/lib/const";
import UnknownVersionNav from "@/src/components/ErrorBoundary/UnknownVersionNav";

export interface WholePageErrorProps {
  gqlErrors?: GraphQLErrors;
  additionalMsg?: string;
  type?:
    | "forbidden"
    | "notFound"
    | "restrictedAccess"
    | "badRequest"
    | "internalError"
    | "serviceUnavailable";
}

const WholePageError = (props: WholePageErrorProps) => {
  const { type, additionalMsg, gqlErrors } = props;

  const handledExceptions = gqlErrors?.filter((err) =>
    [PERMISSION_DENIED, NOT_FOUND, UNKNOWN_VERSION].includes(
      err.extensions.code as string,
    ),
  );

  const errType = type ?? (handledExceptions?.length ? "gql" : "basic");
  const t = useTranslations(`Error.${errType}`);

  let imgSrc;
  switch (type) {
    case "forbidden":
      imgSrc = forbidden;
      break;
    case "notFound":
      imgSrc = robot;
      break;
    case "restrictedAccess":
      imgSrc = lock;
      break;
    default:
      imgSrc = laptop;
  }

  return (
    <Stack
      spacing={5}
      alignItems={"center"}
      justifyContent={"center"}
      sx={styles.innerContainer}
    >
      <Image src={imgSrc} alt={`${type || ""} page image`} width={230} />
      <Stack spacing={1} sx={styles.messageContainer} alignItems={"center"}>
        <Typography variant={"h5"}>{t("title")}</Typography>

        {errType == "gql" ? (
          handledExceptions?.map((err) => (
            <Typography data-testid="gql-err-message" key={err.message}>
              {err.message}
            </Typography>
          ))
        ) : (
          <Typography data-testid="error-message" sx={styles.messageAlignment}>
            {errType == "basic" && additionalMsg
              ? additionalMsg
              : errType == "forbidden"
                ? t.rich("message", {
                    link: (chunks) => (
                      <LinkStyled
                        underline="hover"
                        href={"mailto:mcuxpresso.sdk@nxp.com"}
                        variant="body1"
                      >
                        {chunks}
                      </LinkStyled>
                    ),
                  })
                : t("message")}
          </Typography>
        )}
      </Stack>

      <Stack
        sx={styles.pageErrorActionButtons}
        direction={{ xs: "column", sm: "row" }}
        alignItems={"center"}
        spacing={2}
      >
        {!!handledExceptions?.some(
          (e) => e.extensions.code == UNKNOWN_VERSION,
        ) ? (
          <UnknownVersionNav />
        ) : (
          <ActionButtonsRow type={type} />
        )}
      </Stack>
    </Stack>
  );
};

export default WholePageError;
