/*
 * Copyright 2023-2024 NXP
 */

import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material";
import COLORS from "@/src/lib/colors";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";
import LinkWithoutLocale from "@/src/components/UI/LinkWithoutLocale/LinkWithoutLocale";
import { ForwardedRef, forwardRef } from "react";

const CustomButton = styled(Button)(({ variant }) =>
  variant == "contained"
    ? {
        color: "white",
        backgroundColor: COLORS.teal[400],
        "&:hover": {
          backgroundColor: COLORS.teal[200],
        },
        "&:disabled": {
          backgroundColor: COLORS.grey[100],
        },
      }
    : {
        color: COLORS.teal[400],
        borderColor: variant == "outlined" ? COLORS.teal[200] : "",
        "&:hover": {
          borderColor: variant == "outlined" ? COLORS.teal[400] : "",
        },
      },
);

interface ButtonStyledProps extends ButtonProps {
  ignoreLocale?: boolean;
}

const ButtonStyled = forwardRef(function ButtonStyled(
  props: ButtonStyledProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const linkComponent: { component?: any } = {};
  const { ignoreLocale } = props;

  if (props.href) {
    linkComponent.component = ignoreLocale ? LinkWithoutLocale : LinkWithLocale;
  }

  return <CustomButton ref={ref} {...props} {...linkComponent} />;
});

export default ButtonStyled;
