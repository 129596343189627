/*
 * Copyright 2023-2024 NXP
 */

import { Modals } from "@/src/lib/types";

export const API_URI = process.env.NEXT_PUBLIC_API_URI || "";

// graphql error codes
export const UNAUTHORIZED = "unauthorized";
export const PERMISSION_DENIED = "permissionDenied";
export const NOT_FOUND = "notFound";
export const UNKNOWN_VERSION = "unknownSdkVersion";
export const RESOLVE_COMPONENTS = "resolveComponents";
export const CANNOT_REBUILD = "cannotRebuild";
export const CRITERIA_FAIL = "criteriaFail";
export const CONNECTION_ERROR = "connectionError";

export const HW_NAMES = {
  processor: "Processor",
  board: "Board",
  kit: "Kit",
};

export const REL_TYPES = {
  sdk: "SDK",
  webdata: "WEBDATA",
};

export const MODAL_NAMES: {
  [key: string]: Modals;
} = {
  unresolvedComponents: "unresolvedComponents",
};

export const EXPORT_CODES = {
  disallowed: "0",
  pending: "5",
};
