/*
 * Copyright 2024 NXP
 */

import React from "react";
import styles from "@/src/components/styles";
import ButtonStyled from "@/src/components/UI/ButtonStyled/ButtonStyled";
import { useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";

const UnknownVersionNav = () => {
  const t = useTranslations("Error.buttonsRow");
  const hwName = useSearchParams().get("hw");

  return (
    <>
      <ButtonStyled
        variant="contained"
        href={`/builder?hw=${hwName}`}
        sx={styles.actionButton}
      >
        {t("latest")}
      </ButtonStyled>
      <ButtonStyled
        variant="outlined"
        href={`/select?hardware=${hwName}`}
        sx={styles.actionButton}
      >
        {t("select")}
      </ButtonStyled>
    </>
  );
};

export default UnknownVersionNav;
