/*
 * Copyright 2024 NXP
 */

import React from "react";
import styles from "@/src/components/styles";
import ButtonStyled from "@/src/components/UI/ButtonStyled/ButtonStyled";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRouter } from "@/src/navigation";
import { useTranslations } from "next-intl";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { WholePageErrorProps } from "@/src/components/ErrorBoundary/WholePageError/WholePageError";

const ActionButtonsRow = (props: WholePageErrorProps) => {
  const t = useTranslations("Error.buttonsRow");
  const { type = "basic" } = props;
  const router = useRouter();

  const reloadBtn = type == "basic" && (
    <ButtonStyled
      startIcon={<RefreshIcon />}
      sx={styles.actionButton}
      variant="contained"
      onClick={() => window.location.reload()}
    >
      {t("again")}
    </ButtonStyled>
  );

  const contactBtn = type == "restrictedAccess" && (
    <ButtonStyled
      sx={styles.actionButton}
      variant="contained"
      href={"https://www.nxp.com/support/support:SUPPORTHOME"}
      startIcon={<FeedbackIcon />}
    >
      {t("contact")}
    </ButtonStyled>
  );

  const homepageBtn = !(reloadBtn || contactBtn) && (
    <ButtonStyled variant="contained" href="/" sx={styles.actionButton}>
      {t("homepage")}
    </ButtonStyled>
  );

  return (
    <>
      {reloadBtn}
      {contactBtn}
      {homepageBtn}

      <ButtonStyled
        variant="outlined"
        sx={styles.actionButton}
        onClick={() => router.back()}
      >
        {t("back")}
      </ButtonStyled>
    </>
  );
};

export default ActionButtonsRow;
